<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="LBLBASEINFO" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <!-- [S]결재관련 버튼 -->
                <!-- sysApprovalRequestId: 상세조회시 관련 결재요청일련번호 확인 -->
                <!-- popupParam.isApprContent: 결재요청/결재처리 팝업의 component에 해당 페이지 호출시 true -->
                <!-- approvalStatusCd: 결재요청건에 대한 상태코드 -->
                <!-- popupParam.approvalDocType: TODO(결재할문서에서 상세페이지호출시) -->
                <c-appr-btn 
                  v-if="!popupParam.isApprContent"
                  ref="appr-btn"
                  name="work-permit-appr-btn"
                  :editable="editable"
                  :approvalInfo="approvalInfo"
                  @beforeApprAction="approvalComEval"
                  @callbackApprAction="approvalComEvalCallback"
                  @requestAfterAction="getDetail"
                />
                <c-btn
                  v-if="inputEditable && !disabled && isOld && !popupParam.isApprContent"
                  label="LBLREMOVE"
                  icon="remove"
                  @btnClicked="deleteData" />
                <c-btn
                  v-show="inputEditable && !disabled"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="evalTable"
                  :mappingType="mappingType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
                <!-- <c-btn
                  v-show="inputEditable && isOld"
                  :url="completeUrl"
                  :isSubmit="isComplete"
                  :param="evalTable"
                  mappingType="PUT"
                  label="LBLCOMPLETE"
                  icon="save"
                  @beforeAction="completeData"
                  @btnCallback="completeCallback" /> -->
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-3 col-sm-2 col-md-1 col-lg-1">
                <!--문서번호-->
                <c-text
                  :editable="false"
                  label="LBL0010067"
                  name="saiComplianceEvalTableId"
                  v-model="evalTable.saiComplianceEvalTableId"
                />
              </div>
              <div class="col-xs-6 col-sm-4 col-md-2 col-lg-2">
                <!--평가기간-->
                <c-datepicker
                  :required="true"
                  :range="true"
                  :disabled="disabled"
                  :editable="inputEditable"
                  label="LBL0010052"
                  name="evalDts"
                  v-model="evalTable.evalDts"
                />
              </div>
              <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <!--준수평가 제목-->
                <c-text
                  :required="true"
                  :disabled="disabled"
                  :editable="inputEditable"
                  label="준수평가 제목"
                  name="requirementName"
                  v-model="evalTable.requirementName"
                />
              </div>
              <div class="col-xs-6 col-sm-4 col-md-2 col-lg-2">
                <c-field
                  :editable="inputEditable"
                  :disabled="disabled"
                  label="LBLREVIEWER"
                  name="reviewUserId"
                  v-model="evalTable.reviewUserId" />
              </div>
              <div class="col-xs-6 col-sm-4 col-md-2 col-lg-2">
                <c-field
                  :editable="inputEditable"
                  :disabled="disabled"
                  label="LBLAPPROVER"
                  name="approvalUserId"
                  v-model="evalTable.approvalUserId" />
              </div>
              <div class="col-xs-6 col-sm-4 col-md-1 col-lg-1">
                <c-plant
                  :required="true"
                  :editable="inputEditable"
                  :disabled="disabled"
                  type="edit"
                  name="plantCd"
                  v-model="evalTable.plantCd" />
              </div>
            </template>
          </c-card>
        </div>
      </div>
    </q-form>
    <!--준수평가표-->
    <c-table
      ref="table"
      title="LBL0010068"
      class="q-mt-sm"
      :columns="grid.columns"
      :data="gridData"
      :gridHeight="gridHeight"
      :merge="grid.merge"
      :editable="inputEditable && !disabled"
      :isExcelDown="false"
      :filtering="false"
      :isFullScreen="false"
      :columnSetting="false"
      selection="multiple"
      rowKey="saiComplianceEvalTableResultId"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="inputEditable && !disabled" label="LBLEXCEPT" icon="remove" @btnClicked="deleteTable" />
          <c-btn v-if="inputEditable && !disabled" label="LBLADD" icon="add" @btnClicked="addTable" />
        </q-btn-group>
      </template>
      <template slot="table-chip">
        <q-chip
          v-for="(lawType, idx) in evalTable.compliancesTypes"
          outline square
          :key="idx"
          :removable="false"
          :color="lawType.selected ? (lawType.lawId == 'ALL' ? 'orange' : 'primary') : 'grey-6'"
          text-color="white"
          icon="memory"
          :selected.sync="lawType.selected"
          :label="lawType.lawName"
          v-model="lawType.check"
          @update:selected="state => selectedEvalLaw(state, lawType, idx)"
        >
          <q-tooltip>{{ lawType.lawName }}</q-tooltip>
        </q-chip>
      </template>
      <template v-if="!isRevision" v-slot:customArea="{ props, col }">
        <template v-if="col.name==='lawName'">
          {{props.row[col.name]}}
          <q-badge v-if="props.row.lawRecentInfo" align="top" color="red">
            {{props.row.lawRecentInfo}}
          </q-badge>
        </template>
        <template v-else-if="col.name==='customCol'">
          <component
            :is="imprComponent"
            :col="col"
            :props="props"
            :inputEditable="inputEditable && !disabled"
            :isImmShow="true"
            :requestContentsCols="requestContentsCols"
            tableKey="saiComplianceEvalTableResultId"
            ibmTaskTypeCd="ITT0000160"
            ibmTaskUnderTypeCd="ITTU000170"
            @imprChange="imprChange"
          />
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'complianceEvalTableInfo',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          saiComplianceEvalTableId: '',
          isApprContent: false,
        }
      },
    },
    evalTable: {
      type: Object,
      default(){
        return {
          saiComplianceEvalTableId: '',  // 법규등록부 및 준수평가표 일련번호
          requirementName: '',  // 법규/기타 요건명
          reviewUserId: '',  // 검토자ID
          approvalUserId: '',  // 승인자ID
          plantCd: null,  // 사업장
          sysRevision: 1,  // 시스템 개정번호
          groupId: '',  // 일련번호 키
          revisionNum: '',  // 개정번호
          revisionContent: '',  // 개정사유
          saiComplianceEvalTableStepCd: '',  // 진행상태
          regUserId: '',  // 등록자
          chgUserId: '',  // 수정자
          regDtStr: '', // 제·개정일시
          regUserName: '', // 작성자
          compliances: [],
          deleteCompliances: [],
          compliancesTypes: [],
          startDt: '',
          endDt: '',
        }
      },
    },
    isOld: null,
    inputEditable: null,
    contentHeight: null,
    research: {
      type: Object,
      default: () => ({
        impr: '',
        item: '',
      }),
    },
  },
  data() {
    return {
      period: [],
      selectedId: null,
      gridrev: {
        data: [],
      },
      grid: {
        merge: [
          { index: 0, colName: 'lawId' },
          { index: 1, colName: 'saiLawRegisterMasterId' },
        ],
        columns: [
          {
            name: 'lawName',
            field: 'lawName',
            //법령명
            label: 'LBL0010058',
            align: 'center',
            style: 'width:90px',
            sortable: false,
            type: 'custom',
            fix: true,
          },
          {
            name: 'gubunName',
            field: 'gubunName',
            //구분
            label: 'LBL0010059',
            align: 'left',
            style: 'width:100px',
            sortable: false,
            fix: true,
          },
          {
            name: 'itemName',
            field: 'itemName',
            label: 'LBLITEMS',
            align: 'left',
            style: 'width:120px',
            sortable: false,
            fix: true,
          },
          {
            name: 'relevantClause',
            field: 'relevantClause',
            //관련조항
            label: 'LBL0010060',
            align: 'left',
            style: 'width:90px',
            sortable: false,
            fix: true,
          },
          {
            name: 'requirements',
            field: 'requirements',
            //요구사항
            label: 'LBL0010061',
            align: 'left',
            style: 'width:350px',
            sortable: false,
            type: 'html'
          },
          {
            name: 'applyConstruction',
            field: 'applyConstruction',
            //적용사항
            label: 'LBL0010062',
            align: 'left',
            style: 'width:200px',
            sortable: false,
            type: 'html'
          },
          {
            name: 'target',
            field: 'target',
            //적용대상
            label: 'LBL0010063',
            align: 'left',
            style: 'width:80px',
            sortable: false,
            type: 'html'
          },
          {
            name: 'relatedDocument',
            field: 'relatedDocument',
            //관련문서<br/>(기록)
            label: 'LBL0010064',
            align: 'left',
            style: 'width:80px',
            sortable: false,
            type: 'html'
          },
          {
            name: 'saiComplianceResultCd',
            field: 'saiComplianceResultCd',
            //준수평가결과
            label: 'LBL0010065',
            align: 'center',
            style: 'width:100px',
            sortable: false,
            type: 'select',
            codeGroupCd: 'SAI_COMPLIANCE_RESULT_CD',
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: 'LBLIMPROVE',
            align: 'center',
            style: 'width:100px',
            type: 'custom',
            sortable: false
          },
          {
            name: 'remark',
            field: 'remark',
            label: 'LBLREMARK',
            align: 'left',
            style: 'width:150px',
            sortable: false,
            type: 'textarea'
          },
        ],
      },
      requestImprRow: null,
      editable: true,
      mappingType: 'POST',
      detailUrl: '',
      resultUrl: '',
      revUrl: '',
      revlistUrl: '',
      saveUrl: '',
      deleteUrl: '',
      completeUrl: '',
      isSave: false,
      isRevision: false,
      isComplete: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      selectedEvalLawId: '',
      selectedEvalLawName: '',
      approvalUrl: '',
      isApproval: false,
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return this.evalTable.saiComplianceEvalTableStepCd === "CETS000005"
        // 결재중인 경우 비활성화
        || (this.evalTable.approvalStatusCd === 'ASC0000001' && !this.popupParam.isApprContent)
    },
    gridHeight() {
      return this.contentHeight ? (this.contentHeight - 230) + 'px' : '500px' // 280
    },
    // 개선요청 내용 전달
    requestContentsCols() {
      return ['lawName', 'gubunName', 'itemName'];
    },
    // 개선요청 Component
    imprComponent() {
      let components = () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
      return components;
    },
    gridData() {
      if (this.selectedEvalLawId == 'ALL') {
        return this.evalTable.compliances
      } else {
        return this.evalTable.compliances.filter(data => data.lawId === this.selectedEvalLawId);
      }
    },
    approvalInfo() {
      return {
        sysApprovalRequestId: this.evalTable.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.evalTable.approvalStatusCd, 
        apprEditable: this.editable
          && this.isOld 
          && this.inputEditable, // 결재버튼 활성화 기준
        param: this.evalTable, // 결재 param
        approvalUrl: this.approvalUrl, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000020', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          saiComplianceEvalTableId: this.evalTable.saiComplianceEvalTableId,
          isApprContent: true
        },
        approvalRequestName: this.evalTable.requirementName, // 결재요청명 (문서 title)
        approvalConnId: this.evalTable.saiComplianceEvalTableId, // 결재연결용 업무일련번호 (문서 key)
      }
    },
    // [E] 결재관련 버튼 컨트롤
  },
  watch: {
    'research.item'() {
      this.getResults();
    }
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sai.evalTable.complianceEvalTable.get.url;
      this.resultUrl = selectConfig.sai.evalTable.complianceEvalTable.results.url;
      this.revlistUrl = selectConfig.sai.evalTable.complianceEvalTable.rev.url;
      this.revUrl = selectConfig.com.revision.getversion
      this.saveUrl = transactionConfig.sai.evalTable.complianceEvalTable.insert.url;
      this.approvalUrl = transactionConfig.sai.evalTable.complianceEvalTable.update.url;
      this.deleteUrl = transactionConfig.sai.evalTable.complianceEvalTable.delete.url;
      this.completeUrl = transactionConfig.sai.evalTable.complianceEvalTable.complete.url;
      // code setting
      // list setting
      this.getData();
    },
    getData() {
      this.isRevision = false;
      if (this.popupParam.saiComplianceEvalTableId) {
        this.selectedId = this.$_.clone(this.popupParam.saiComplianceEvalTableId);
        this.getDetail();
      }
    },
    getDetail() {
      this.$http.url = this.$format(this.detailUrl, this.popupParam.saiComplianceEvalTableId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        // 상세팝업 최초작성/최근수정 정보 노출
        this.$emit('setRegInfo', _result.data)

        this.$_.extend(this.evalTable, _result.data);
        
        this.setCompliancesTypes();
      },);
    },
    getResults() {
      this.$http.url = this.resultUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        saiComplianceEvalTableId: this.popupParam.saiComplianceEvalTableId
      };
      this.$http.request((_result) => {
        this.$set(this.evalTable, 'compliances', _result.data)
      },);
    },
    // 개선창 닫기 후
    imprChange(data) {
      this.$set(this.research, 'impr', uid())
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
    },
    addTable() {
      this.popupOptions.title = 'LBL0010069'; // 법규 등록부
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/sai/law/register/lawRegisterPop.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeTablePopup;
    },
    closeTablePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEachRight(data, item => {
          if (this.$_.findIndex(this.evalTable.compliances, { saiLawRegisterMasterItemId: item.saiLawRegisterMasterItemId }) === -1) {
            let _idx = this.$_.findIndex(this.evalTable.compliances, { saiLawRegisterMasterId: item.saiLawRegisterMasterId })
            _idx = _idx > -1 ? _idx : 0;
            this.evalTable.compliances.splice(_idx, 0, {
              saiComplianceEvalTableResultId: uid(),  // 법규등록부 및 준수평가표 결과 일련번호
              saiComplianceEvalTableId: this.popupParam.saiComplianceEvalTableId,  // 법규등록부 및 준수평가표 일련번호
              saiLawRegisterMasterItemId: item.saiLawRegisterMasterItemId,  // 법규등록부 항목 일련번호
              saiComplianceResultCd: 'ETR0000001',  // 준수평가결과
              remark: '',  // 비고
              lawId: item.lawId, 
              lawName: item.lawName, 
              gubunName: item.gubunName,
              saiLawRegisterMasterId: item.saiLawRegisterMasterId, 
              itemName: item.itemName, 
              relevantClause: item.relevantClause, 
              requirements: item.requirements, 
              applyConstruction: item.applyConstruction, 
              target: item.target,
              relatedDocument: item.relatedDocument,
              editFlag: 'C',
              regUserId: this.$store.getters.user.userId,
            })
          }
        })

        this.setCompliancesTypes();
      }
    },
    deleteTable() {
      let selectData = this.$refs[`table`].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGEXCEPT', // 제외하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            if (!this.evalTable.deleteCompliances) this.evalTable.deleteCompliances = [];
            this.$_.forEach(selectData, item => { 
              if (item.editFlag !== 'C'
                && this.$_.findIndex(this.evalTable.deleteCompliances, { saiComplianceEvalTableResultId: item.saiComplianceEvalTableResultId })) {
                  this.evalTable.deleteCompliances.push(item)
              }
              this.evalTable.compliances = this.$_.reject(this.evalTable.compliances, item);
            })
            this.setCompliancesTypes();

            this.$refs[`table`].$refs['compo-table'].clearSelection();
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveData() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.sai.evalTable.complianceEvalTable.update.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sai.evalTable.complianceEvalTable.insert.url;
        this.mappingType = 'POST';
      }
      let saveMsg = 'MSGSAVE';
      // if (this.isRevision) {
      //   saveMsg = 'MSGREVSAVE';
      //   // 현재버전을 개정하여 저장하시겠습니까?
      // }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: saveMsg,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.evalTable.regUserId = this.$store.getters.user.userId;
              this.evalTable.chgUserId = this.$store.getters.user.userId;
              // 개정시
              if (this.isRevision) {
                this.saveUrl = transactionConfig.sai.evalTable.complianceEvalTable.insert.url;
                this.mappingType = 'POST';
                this.evalTable.sysRevision = parseInt(this.evalTable.sysRevision) + 1;
              }
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (!this.isOld) {
        this.$set(this.popupParam, 'saiComplianceEvalTableId', result.data)
      }
      this.getData();
    },
    completeData() {
      if (!this.evalTable.compliances || this.evalTable.compliances.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '준수평가를 하나 이상 입력하세요.', // 준수평가를 하나 이상 입력하세요.
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGCOMPLETE', // 완료하시겠습니까?,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.evalTable.regUserId = this.$store.getters.user.userId;
              this.evalTable.chgUserId = this.$store.getters.user.userId;
              
              this.isComplete = !this.isComplete;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getData();
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.saiComplianceEvalTableId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    setCompliancesTypes() {
      this.evalTable.compliancesTypes = [];
      this.evalTable.compliancesTypes.push({
        lawId: 'ALL',
        lawName: '전체',
        selected: true,
      })
      this.$_.forEach(this.evalTable.compliances, item => { 
        if (this.$_.findIndex(this.evalTable.compliancesTypes, { lawId: item.lawId }) === -1) {
          this.evalTable.compliancesTypes.push({
            lawId: item.lawId,
            lawName: item.lawName,
            selected: false,
          })
        }
      })
      this.selectedEvalLaw(false, {lawId: 'ALL', lawName: '전체'}, 0);
    },
    selectedEvalLaw(state, lawType, idx) {
      this.selectedEvalLawId = lawType.lawId;
      this.selectedEvalLawName = lawType.lawName;
      if (state) {
        this.evalTable.compliancesTypes.forEach((item, index) => {
          if (idx !== index) {
            this.$set(item, 'selected', false)
          } else {
            this.$set(item, 'selected', true)
          }
        })
      } else {
        this.$set(this.evalTable.compliancesTypes[idx], 'selected', true)
      }
    },
    approvalComEval() {
      /**
       * 유효성 체크
       *  1. 점검결과가 하나라도 입력되었는지?
       */
      let isIng = true;
      if (!this.evalTable.compliances || this.evalTable.compliances.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '준수평가대상을 하나이상 입력하세요.', // 점검결과를 하나 이상 입력하세요.
          type: 'warning', // success / info / warning / error
        });
        isIng = false;
      } 

      if (isIng) {
        this.$refs['editForm'].validate().then(_result => {
          if (_result) {
            window.getApp.$emit('CONFIRM', {
              title: 'LBLCONFIRM',
              // 결재요청 하기 전 입력된 값을 저장합니다.\n\r진행하시겠습니까?
              message: '결재요청 하기 전 입력된 값을 저장합니다.\n진행하시겠습니까?',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.evalTable.regUserId = this.$store.getters.user.userId
                this.evalTable.chgUserId = this.$store.getters.user.userId
                
                this.isApproval = !this.isApproval
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            window.getApp.$emit('APP_VALID_ERROR');
          }
        });
      }
    },
    // [S] 결재관련 
    approvalComEvalCallback(result) { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
  }
};
</script>